
import { Component, Vue } from "vue-property-decorator";
import Wysiwyg from "@/components/reusable/Wysiwyg.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Icon from "@/components/reusable/Icon.vue";
import { PartModel, PartModelRequest } from "@/models/part";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import PartMenu from "@/components/part/PartMenu.vue";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import PartService from "@/services/part_service";
import UIkit from "uikit";
import { EventBus } from "@/events/index";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
// import { CategoryModel } from "@/models/category";
import { Validations } from "vuelidate-property-decorators";
import { required, sameAs, not } from "vuelidate/lib/validators";
import { noFwdSlash } from "@/validators/custom";
import { APIResponse } from "@/models/api_res";
// import CategoryService from "@/services/category_service";
import { AuthError, NotFoundError } from "@/services/error_service";
import ImagePicker from "@/components/reusable/ImagePicker.vue";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
import AssetFileSelector from "@/components/asset/AssetFileSelector.vue";
import { AssetModel } from "@/models/asset";
import { getImage } from "@/utility/asset";

Component.registerHooks(["beforeRouteLeave"]);
@Component({
  components: {
    Wysiwyg,
    FormInput,
    Icon,
    Autocomplete,
    PartMenu,
    ImagePicker,
    AssetFileSelector,
  },
})
export default class PartEditor extends Vue {
  protected showWarningModal = false;
  protected partService = new PartService();
  // protected categoryList: CategoryModel[] = [];
  protected getImage = getImage;
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddBusinessUnit))
  setBusinessUnit: any;

  protected justPosted = false;
  protected id = 0;
  // protected categoryService = new CategoryService();
  protected deleteData: PartModel[] = [];
  protected isNew = false;
  protected isDupe = false;
  public originalPart = {} as PartModel;
  protected part = {
    is_hidden: false,
    // categories: [] as CategoryModel[],
  } as PartModel;
  protected imageKey = 0;

  @Validations()
  validations() {
    if (!this.isDupe) {
      return {
        part: {
          item_number: { required, noFwdSlash },
          display_name: { required },
        },
      };
    } else {
      return {
        part: {
          item_number: {
            required,
            noFwdSlash,
            dupe: not(sameAs(() => this.originalPart.item_number)),
          },
          display_name: {
            required,
            dupe: not(sameAs(() => this.originalPart.display_name)),
          },
        },
      };
    }
  }

  async created() {
    if (!this.$route.params.id) {
      this.isNew = true;
      if (this.$route.query.dupe) {
        this.isDupe = true;
        this.id = parseInt(this.$route.query.dupe as string, 10);
        this.getSinglePart();
      } else if (this.$route.query.parent) {
        // Vue.set(this.part, 'manufacturer', {} as MfrModel)
        // const parent = await this.getCategory(
        //   parseInt(this.$route.query.parent as string, 10)
        // );
        // this.part.categories!.push(parent);
      }
    } else {
      this.id = parseInt(this.$route.params.id, 10);
      this.getSinglePart();
    }
  }
  mounted() {
    EventBus.$on("sendImage", (files: AssetModel[]) => {
      this.part.image = files[0];
      this.showWarningModal = true;
      this.imageKey += 1;
    });
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <PartMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: PartModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "part" },
        { height: "auto", adaptive: true }
      );
    });
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    EventBus.$off("sendImage");
    EventBus.$off("sendDiagram");
    EventBus.$off("sendDocs");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal",
    ];
    modals.forEach((selector) => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }

  protected removeImage(): void {
    (this.part.image as PartModelRequest["image"]) = 0;
    this.showWarningModal = true;
    this.imageKey += 1;
  }

  protected showFilePicker(): void {
    this.$modal.show(
      AssetFileSelector,
      {},
      {
        adaptive: true,
        height: "100%",
        width: "875px",
        classes: "asset-file-selector",
      }
    );
  }

  // protected async getCategories(optionsObject?: {}): Promise<void> {
  //   try {
  //     const res: APIResponse = await this.categoryService.getCategories(
  //       optionsObject
  //     );
  //     this.categoryList = res.results;
  //   } catch (err) {
  //     if (err instanceof AuthError) {
  //       AuthError.logout();
  //     } else {
  //       EventBus.$emit("showError", err.message);
  //     }
  //   }
  // }

  protected saveKeypress(): void {
    this.save();
  }

  protected get cdn(): string {
    return process.env.VUE_APP_CDN_URL;
  }

  // protected async getCategory(id: number): Promise<CategoryModel> {
  //   let cat = {} as CategoryModel;
  //   try {
  //     const res = await this.categoryService.getSingleCategory(id);
  //     cat = res;
  //   } catch (err) {
  //     if (err instanceof AuthError) {
  //       AuthError.logout();
  //     } else {
  //       EventBus.$emit("showError", err.message);
  //     }
  //   }
  //   return cat;
  // }

  protected get website(): string {
    if (this.businessUnit == 'bmh') {
      return process.env.VUE_APP_C5_URL;
    } else {
      return process.env.VUE_APP_WORKMASTER_URL;
    }
  }

  // protected updateCategory(item: { id: number; display_name: string }) {
  //   if (!this.part.categories) {
  //     this.part.categories = [];
  //   }
  //   const cat = this.categoryList.filter((cat) => {
  //     if (item.id === cat.id) {
  //       return cat;
  //     }
  //   });
  //   if (!cat[0].is_leaf) {
  //     EventBus.$emit(
  //       "showError",
  //       `Cannot link part to non-leaf category, <strong>${cat[0].display_name}</strong>.`
  //     );
  //   } else {
  //     this.part.categories.push(cat[0]);
  //   }
  // }

  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    this.showWarningModal = false;
    try {
      await this.partService.deletePart(id);
      this.$router.push({
        path: "/part",
        query: { deleted: encodeURI(`${this.part.title}`), type: "part" },
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected cancel(): void {
    this.$router.go(-1);
  }

  protected save(): void {
    this.setLoading(true);
    this.$v.part.$touch();
    if (!this.$v.$invalid) {
      this.showWarningModal = false;
      this.finalizeTitle();
      this.makeRequest();
    } else {
      this.$nextTick(() => {
        (this.$refs.requiredFields as HTMLDivElement).scrollIntoView();
      });
    }
    this.setLoading(false);
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.showWarningModal) {
      UIkit.modal
        .confirm(
          `
    <div class="uk-modal-header uk-flex uk-flex-middle">
      <div class="uk-flex-none">
        <span
          uk-icon="icon: warning; ratio:1.5;"
          class="red no-hover uk-margin-small-right"
        ></span>
      </div>
      <div>
        <h2 class="uk-modal-title uk-margin-remove">
          You have not saved your changes!
        </h2>
      </div>
    </div>
    <div class="uk-modal-body">
      Would you like to continue without saving your changes?
    </div>`
        )
        .then(
          function () {
            next();
          },
          function () {
            next(false);
          }
        );
    } else {
      next();
    }
  }

  protected showWarning(isVisible: boolean): void {
    this.showWarningModal = isVisible;
  }

  protected makeRequest(): void {
    if (this.isNew) {
      this.postNew();
    } else {
      this.saveExisting();
    }
  }

  protected finalizeTitle(): void {
    if (!this.part.title) {
      this.part.title = this.part.display_name as string;
    }
  }

  protected async getSinglePart(): Promise<void> {
    this.setLoading(true);
    try {
      const res: PartModel = await this.partService.getSinglePart(
        this.id
      );
      this.part = res;
      if (this.businessUnit !== "workmaster") { // 2023-06-27_TLT: temp fix. hardcoding the workmaster BU until we have the chance to migrate this Go API endpoint to the Node API
        this.setBusinessUnit("workmaster");
      }
      this.originalPart = { ...res };
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof NotFoundError) {
        this.$router.replace({
          name: "NotFound",
          query: { error: encodeURI(err.message) },
        });
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async returnSinglePart(id: number): Promise<PartModel> {
    let res = {} as PartModel;
    try {
      const data: PartModel = await this.partService.getSinglePart(id);
      res = data;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return res;
  }

  protected async postNew(): Promise<void> {
    const req = this.buildRequest();
    delete req.id;
    try {
      const res = await this.partService.createNewPart(req);
      this.part = res;
      this.$router.push({
        path: "/part/edit/" + res.id,
        query: { created: encodeURI(`${this.part.title}`), type: "part" },
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async saveExisting(): Promise<void> {
    const req = this.buildRequest();
    try {
      const res = await this.partService.savePart(
        req,
        this.part.id as number
      );
      this.getSinglePart();
      if (this.$route.query.created) {
        this.$router.push({ query: {} });
      }
      this.showWarningModal = false;
      EventBus.$emit(
        "showSuccess",
        `Part <strong>${this.part.display_name}</strong> has been saved.`,
        []
      );
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * IMPORTANT: You may need to add/manipulate any new fields in this method!
   *
   * Modifying object structure into what API expects on POST/PATCH requests
   *
   */
  protected buildRequest(): PartModelRequest {
    const req: any = { ...this.part, is_part: true, bu: this.businessUnit };
    if (this.part.image && typeof this.part.image !== "number") {
      req.image = (this.part.image as AssetModel).id;
    }
    if (req.image === 0) {
      delete req.image;
    }
    if (req.diagram) {
      delete req.diagram;
    }
    return req;
  }

  /**
   * Remove duplicate ids from arrays sent into POST/PATCH /part requests.
   * Duplicates in any data will throw a 409
   * Relevant for arrays of ids {number[]}: related, categories, downloads
   *
   * */
  protected removeDupe(arr: number[]): number[] {
    const result: number[] = [];
    arr.forEach((item: number, index: number) => {
      if (arr.indexOf(item) == index) result.push(item);
    });
    return result;
  }

  get editor3(): string {
    return "part_desc";
  }

  /**
   * Method that reads updates from the Quill text editors <Wsywig>
   * Each Wsywig is identified by "editor{number}" (computed) and mapped to a specific data field
   *
   * NOTE: There is only 1 wsywig on this page, so dataName doesn't need to be read
   */
  protected receiveUpdates(dataName: string, data: any): void {
    this.part.description = data;
  }
}
